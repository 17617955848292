import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import { Organization } from '../api/Organization';
import ButtonSecondary, { SecondaryButtonType } from '../components/ButtonSecondary';
import ContentContainer from '../components/ContentContainer';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { FetchImageStatus, fetchImage } from '../image';

import './ProfilePage.css'


interface DisabledInputProps {
  label: string,
  value: string,
}
function DisabledInputField({label, value}:DisabledInputProps): React.ReactElement {
  return (
    <div className="ProfilePage-disabled-input-field">
      <label htmlFor={label}>
        {label}
        <input id={label} disabled value={value}/>
      </label>
    </div>
  )
}

interface DisplayImageProps {
  org: Organization,
  account: Account,
}
function DisplayImage({org,account}:DisplayImageProps): React.ReactElement {
  const {t} = useTranslation<string>();
  const { image, status } = fetchImage({org, account});

  if (org.profilePageOptions.uploadImage()) {
    return (
      <div className='ProfilePage-image-container'>
        { status===FetchImageStatus.Loading && <div className='ProfilePage-image-loading-background'><div className='ProfilePage-image-loading'></div></div> }
        { status===FetchImageStatus.Found && image && <img className='ProfilePage-image' src={`data:image;base64, ${image}`} alt={t('Profile picture')} /> }
        { status===FetchImageStatus.Error && <p className='ProfilePage-image-error-text'>{t('Error fetching your image')}</p> }
      </div>
    )
  } else return(<></>)
}

interface Props {
  account: Account,
}
function ProfilePage({account}:Props): React.ReactElement {
  const org = useOrganization();
  const {t} = useTranslation<string>();

  useDocumentTitle({title: 'Profile page'});

  return (
    <ContentContainer
      header={t('Personal information')}
      title={t('Your account')}
    >
      { org.profilePageOptions.uploadImage() && <DisplayImage org={org} account={account}/>}

      <div className='ProfilePage-group-fields'>
        <DisabledInputField label={t('Username')} value={account.username}/>
        <DisabledInputField label={t('Name')} value={account.name}/>
        <DisabledInputField label={t('E-Mail')} value={account.email}/>
        <DisabledInputField label={t('Phone number')} value={account.phone}/>
      </div>

      <div className='ProfilePage-button-container'>
        <ButtonSecondary buttonText={t('Change password')} type={SecondaryButtonType.link} linkTo={`/${org.id}/pwchange`} fullWidth></ButtonSecondary>
        { org.profilePageOptions.uploadImage() && <ButtonSecondary type={SecondaryButtonType.link} buttonText={t('Upload or change image')} linkTo={`/${org.id}/image`} fullWidth></ButtonSecondary> }
        { org.profilePageOptions.changePincode() && <ButtonSecondary buttonText={t('Create or change PIN-code')} type={SecondaryButtonType.link} linkTo={`/${org.id}/pincode`} fullWidth></ButtonSecondary> }
        { org.profilePageOptions.orderAccessCard() && <ButtonSecondary buttonText={t('Order access card')} type={SecondaryButtonType.link} linkTo={`/${org.id}/accesscard`} fullWidth></ButtonSecondary> }
      </div>

    </ContentContainer>
  )

}


export default ProfilePage;