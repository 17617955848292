import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import InfoImage1 from './infoimage1.png';
import InfoImage2 from './infoimage2.png';
import InfoImage3 from './infoimage3.png';
import InfoImage4 from './infoimage4.png';
import { useOrganization } from '../OrganizationContext';
import ActivationStageContainer from '../components/ActivationStageContainer';
import Button from '../components/Button';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './ConsentInfoStage.css';


interface Props {
  onCompleted: () => void;
  getCurrentStep: number;
}

function ConsentInfoStage({ onCompleted, getCurrentStep }: Props): React.ReactElement {  

  const { t } = useTranslation<string>();
  const org = useOrganization()
  const orgFullname = org.name.localize()

  const [currentStep, setCurrentStep] = useState(getCurrentStep);
  useDocumentTitle({title: 'ICT regulations'})

  function nextPage() {
    if (currentStep === 5) {
      onCompleted()
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  function previousPage() {
    if(currentStep > 1 ) {
      setCurrentStep(currentStep - 1);
    }
  }

  function renderTitle() {
    switch (currentStep) {
      case 1:
        return t('Introduction to ICT regulations');
      case 2:
        return t('Your account is personal');
      case 3:
        return t('Follow laws and regulations');
      case 4:
        return t('Report security violations');
      case 5:
        return t('Violation of the ICT regulations');
      default:
        throw new Error('Unhandled step');
    }
  }

  function renderImage() {
    switch (currentStep) {
      case 1:
        return <></>;
      case 2:
        return <img className="ConsentInfoStage-image" src={InfoImage1} alt="" />;
      case 3:
        return <img className="ConsentInfoStage-image" src={InfoImage2} alt="" />;
      case 4:
        return <img className="ConsentInfoStage-image" src={InfoImage3} alt="" />;
      case 5:
        return <img className="ConsentInfoStage-image" src={InfoImage4} alt="" />;
      default:
        throw new Error('Unhandled step');
    }
  }

  function renderBody() {
    switch (currentStep) {
      case 1:
        return <p>{t('Before you can access your account, you must go through a short presentation showing key topics of the organization’s IT regulations.')}</p>;
      case 2:
        return (
          <ul className="ConsentInfoStage-ul">
            <li>{t('Prevent others from accessing your account.')}</li>
            <li>{t('Protect your passwords and devices such as mobile and computer.')}</li>
            <li>{t('Attempting to gain access to someone else’s account is prohibited.')}</li>
          </ul>
        );
      case 3:
        return (
          <ul className="ConsentInfoStage-ul">
            <li>{t('Respect copyright to software, data, publications, images, music, movies, etc.')}</li>
            <li>{t('Do not make changes to the organization’s IT systems without permission.')}</li>
            <li>{t('Do not use the IT systems in a way that risks the organization’s reputation.')}</li>
          </ul>
        );
      case 4:
        return (
          <Trans>
            <p>
              If you become aware of issues that may be relevant to IT security and privacy at the organization, notify the <a target="_blank" rel="noopener noreferrer" href={org.itDepLink.localize()}>IT division</a>
            </p>
          </Trans>
        );
      case 5:
        return (
          <>
            <p>{t('If you violate these regulations, you may')}:</p>
            <ul className="ConsentInfoStage-ul">
              <li><Trans>Lose access to all or parts of {{ orgFullname }}’s IT systems.</Trans></li>
              <li>{t('Be subjected to diciplinary actions, criminal liability or legal responsibility for damages. Students may be excluded from studies and exams.')}</li>
            </ul>
          </>
        );
      default:
        throw new Error('Unhandled step');
    }
  }

  const btn_next = <Button onClick={nextPage} >{currentStep === 1 ? t('Start') : t('Next')}</Button>;
  
  let btn_back = undefined
  if(currentStep>1) {
    btn_back = <Button onClick={previousPage}>{t('Back')}</Button>
  }
  
  return (
    <ActivationStageContainer title={renderTitle()} currentStep={currentStep} btn_next={btn_next} btn_back={btn_back}>
      {renderImage()}
      <div className="ConsentInfoStage-section">
        {renderBody()}
      </div>
    </ActivationStageContainer>
  );
}

export default ConsentInfoStage;
