import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Dropdown from './Dropdown';
import LanguageSelector from './LanguageSelector';
import { useAccountName } from '../AccountContext';
import { useOptionalOrganization } from '../OrganizationContext';
import { MissingUserError } from '../api/Account';

import './TopBar.css';


function TopBar(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOptionalOrganization();
  const {account, error, fetchAccount} = useAccountName();

  let orgHeader = <></>;
  if (org) {
    const localizedOrgName = org.name.localize();
    const redirectLink = `/${org.id}/`
    const logoWide = org.topbarLogo.localize();
    const location = useLocation();
    orgHeader = <>
      <Link className='Topbar-logo-redirect' to={redirectLink} onClick={() => location.pathname==`/${org.id}/activate` && fetchAccount()}>
        <img className="TopBar-logo sq" src={`/static/logo/${org.id}.svg`} alt={t('{localizedOrgName} logo', {localizedOrgName})} />
        <img className="TopBar-logo wide" src={`/static/logo/${logoWide}`} alt= {t('{localizedOrgName} logo', {localizedOrgName})}/>
      </Link>
    </>;
  }
  let userDrowdown = undefined;
  const logoutPage = org ? `/${org.id}/loggedout` : '/loggedout'
  const logoutLink = '/api/logout?' + (new URLSearchParams({ 'return': logoutPage })).toString();
  // The user is logged in and account is found:
  if (account?.name !== undefined) {    
    userDrowdown = (
      <Dropdown id="name-dropdown" title={account.name}>
        <li><a href={logoutLink}>{t('Logout')}</a></li>
      </Dropdown>
    );
    // The user got logged in but no account found at the org:
  } else if (error instanceof MissingUserError) {
    userDrowdown = (
      <a className='TopBar-logout-button' type='button' href={logoutLink}>{t('Logout')}</a>
    )
  }

  return (
    <div className="TopBar">
      {orgHeader}
      {userDrowdown}
      <span className="TopBar-text TopBar-language full"><LanguageSelector /></span>
    </div>
  );
}

export default TopBar;
