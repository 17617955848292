import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';


interface Props {
  title: string;
}

export function useDocumentTitle({title}:Props):void {
  const { t } = useTranslation();
  const org = useOrganization();
  const orgName = org.name.localize();
  const language = document.documentElement.lang
  const translatedTitle = t(title)
  const orgAdjustedTranslatedTitle = org ? `${translatedTitle} - ${orgName}` : `${translatedTitle} - Account Claim`;

  useEffect(() => {
    document.title = orgAdjustedTranslatedTitle;
  }, [title,language])

}