import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import ButtonSecondary, { SecondaryButtonType } from '../components/ButtonSecondary';
import { Contacts } from '../components/ContactAndHelpModal';
import ContentContainer from '../components/ContentContainer';
import IDPortenLoginButton from '../components/IDPortenLoginButton';
import Modal from '../components/Modal';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './PasswordChangeHasNINPage.css';


interface NoIDPortenModalProps {
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
}
function NoIDPortenModal({ isOpen, onRequestClose }: NoIDPortenModalProps) {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  
  const orgName = org.name.localize()

  return <Modal
    id="no-login-idporten"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={t('Cannot log in using ID-porten')}
  >
    <p>{t('If you cannot log in using ID-porten, you will need to contact the helpdesk at {{orgName}}.', { orgName })}</p>
    <Contacts />
  </Modal>
}

function PasswordChangeHasNINPage(): React.ReactElement {
  const org = useOrganization();
  const { t } = useTranslation<string>();
  const [showNoIDPortenModal, setShowNoIDPortenModal] = useState(false);

  useDocumentTitle({title: 'Log in to change password'})

  return (
    <ContentContainer
      header={t('New password')}
      title={t('Set new password')}
    >
      <p className="PasswordChangeHasNINPage-intro">
        {t('Your user account is registered with a national identity number. You can therefore use ID-porten to log in and change your password.')}
      </p>

      <div className="PasswordChangeHasNINPage-idporten">
        <IDPortenLoginButton returnTo={`/${org.id}/pwchange`} />
      </div>

      <div className="PasswordChangeHasNINPage-not-idporten">
        <ButtonSecondary 
          type={SecondaryButtonType.button} 
          onClick={(e) => {setShowNoIDPortenModal(true); e.preventDefault(); return false;}} 
          buttonText={t('Cannot use ID-porten')} 
          fullWidth />
      </div>

      <NoIDPortenModal isOpen={showNoIDPortenModal} onRequestClose={()=>{setShowNoIDPortenModal(false)}} />
    </ContentContainer>
  );
}

export default PasswordChangeHasNINPage;
