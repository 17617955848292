import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import {
  ConfigAccountUserError,
  ContextBasedPasswordError,
  PasswordCriteriaNotMetError,
  PasswordFoundInBreachError,
  PasswordHasBeenUsedError,
  configAccount,
} from '../api/ConfigAccount';
import Button from '../components/Button';
import ContentContainer from '../components/ContentContainer';
import LoadingSpinner from '../components/LoadingSpinner';
import PasswordForm from '../components/PasswordForm';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';


interface Props {
  account: Account;
  onCompleted: () => void;
}

function SetPasswordPage({ account, onCompleted }: Props): React.ReactElement {

  const [error, setError] = useState<React.ReactElement>();
  const [oldPassword, setOldPassword] = useState<string|undefined>();
  const org = useOrganization();
  const orgname = org.name.localize();
  const [password, setPassword] = useState<string|undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation<string>();
  const passwordPolicyLink = org.localPasswordPolicyLink ? org.localPasswordPolicyLink.localize() : org.supportLink?.localize();
  const urlHelper = useURLHelper();

  useDocumentTitle({title: 'Set password'});

  const savePassword = async () => {
    setIsLoading(true);
    if (password === undefined) {
      // The button to set the password is not available unless the password is
      // valid, so this should never happen.
      throw new Error('Attempted to set invalid password.');
    }
    try {
      await configAccount({
        activate: false,
        password: password,
        orgID: org.backendId,
        accountID: account.id,
      });
    } catch(e: unknown) {
      if (e instanceof PasswordFoundInBreachError) {        
        setError(<Trans>Desired password was found in a breach. Read more at: <a target="_blank" rel="noopener noreferrer" href='https://haveibeenpwned.com'>haveibeenpwned.com</a></Trans>);
      } else if (e instanceof ContextBasedPasswordError) {
        setError(<Trans>Password cannot contain information related to {{orgname}}.</Trans>)
      } else if (e instanceof PasswordHasBeenUsedError) {
        setOldPassword(password);
      } else if (e instanceof PasswordCriteriaNotMetError) {
        setError(<Trans>Password does not meet the required criteria. Read more at: <a target="_blank" rel="noopener noreferrer" href={passwordPolicyLink}>{{passwordPolicyLink}}</a></Trans>)
      } else if (e instanceof ConfigAccountUserError) {
        setError(<Trans>{(e as Error).message}</Trans>)
      } else {
        const msg = 'Failed to set password for user: ' + (e as Error).message;
        urlHelper.genericError(msg);
      }
      return;
    } finally {
      setIsLoading(false);
    }
    onCompleted();
  };

  const footer = <div className="ActivationStageContainer-button-container">
    <Button onClick={savePassword} disabled={!password || isLoading}>
      { isLoading ? <><LoadingSpinner ariaLabel={t('Saving password')}/> {t('Saving password')} </> 
        : t('Save password')}
    </Button>
    <Button onClick={() => urlHelper.redirectPush(`/${org.id}/profile`)}>{t('Back')}</Button>
  </div>;
  return <>
    <ContentContainer footer={footer} header={t('New password')} title={t('Set password')}>
      <PasswordForm
        account={account}
        error={error}
        oldPassword={oldPassword}
        onChange={setPassword}
      />
    </ContentContainer>
  </>
}

export default SetPasswordPage;
