import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BritainFlag from './britainFlag.svg';
import NorwegianFlag from './norwegianFlag.svg';

import './LanguageSelector.css';


function LanguageSelector(): React.ReactElement {
  const { i18n } = useTranslation<string>();
  const [language,setLanguage] = useState<string>(i18n.language)
  const languageObjects = [
    {
      'language':'nb',
      'languageLabel':'Norsk bokmål',
    },{
      'language':'nn',
      'languageLabel':'Norsk nynorsk',
    },{
      'language':'en',
      'languageLabel':'English',
    },
  ]

  let currentFlag = NorwegianFlag;
  let currentLanguageLabel ='Norsk bokmål';
  
  document.documentElement.lang = i18n.language;

  switch (language) {
    case 'en':
      currentFlag = BritainFlag;
      currentLanguageLabel = 'English';
      break;
    case 'nn':
      currentFlag = NorwegianFlag;
      currentLanguageLabel = 'Norsk nynorsk';
      break;
    case 'nb':
      currentFlag = NorwegianFlag;
      currentLanguageLabel = 'Norsk bokmål';
      break;
    default:
      currentFlag = NorwegianFlag;
      currentLanguageLabel = 'Norsk bokmål';
      break;
  }

  function onChangeHandler(event: React.ChangeEvent<HTMLSelectElement>): void {
    setLanguage(event.target.value);
    document.documentElement.lang = event.target.value;
    i18n.changeLanguage(event.target.value)
  }

  return (
    <>
      <div className='LanguageSelector'>
        <div className='LanguageSelector-choice'>
          <label htmlFor='languages'>{i18n.t('Choose language')+': '}</label>
          <select className='LanguageSelector-select' onChange={onChangeHandler} id='languages' defaultValue={language}>
            {languageObjects.map((languageObject,index) => {
              return (
                <option key={index} value={languageObject.language}>
                  {languageObject.languageLabel}
                </option>
              )
            })}
          </select>
        </div>
        <div className='LanguageSelector-current'>
          <img className="LanguageSelector-image" src={currentFlag} alt={currentLanguageLabel}/>
        </div>
      </div>
    </>)
}

export default LanguageSelector